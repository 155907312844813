<template>
  <div class="personAdmin">
    <div class="facilityBox" style="max-height:84.7vh"> 
      <div class="topbtns">
        <el-button type="success" size="medium" @click="addPerson('add')" icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button type="danger" size="medium" @click="deleteUser" icon="el-icon-remove-outline">删除</el-button>
      </div>
      <el-form
        class="searchForm"
        :model="searchForm"
        ref="searchForm">
        <el-form-item label="姓名" prop="EnterpriseFullName" label-width="50px">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="日期查询" prop="field3" label-width="90px">
          <el-date-picker
            v-model="searchForm.dateTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="primary" size="medium" @click="resetForm" icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        row-key="ID"
        @selection-change="tableSelectionChange"
        :header-cell-style="{color: '#666', background: '#f0f0f0'}"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
          :reserve-selection="true"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="sindex"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column align="center" width="120" prop="ChineseName" label="员工姓名">
          <template slot-scope="scope">
            {{ scope.row.ChineseName }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="120" prop="Name" label="员工手机号">
          <template slot-scope="scope">
            {{ scope.row.Name }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="IdentityCard"
          label="员工身份证号"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.IdentityCard }}
          </template>
        </el-table-column> -->
        <el-table-column align="center" width="120" prop="Title" label="职务">
          <template slot-scope="scope">
            {{ scope.row.Title }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" prop="RoleStatus" label="用户状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.Enabled ? 'success' : 'danger'">{{scope.row.Enabled ? '启用' : '停用'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="260" prop="RoleName" label="获得角色">
          <template slot-scope="scope">
            {{ scope.row.RoleName }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="180" prop="RoleStatus" label="角色使用状态">
          <template slot-scope="scope">
            <div>{{ scope.row.RoleStatus }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="180" prop="CanViewTrace" label="是否可查看北斗轨迹">
          <template slot-scope="scope">
            <el-switch :active-value="1" :inactive-value="0"
             v-model="scope.row.CanViewTrace"
             active-color="#13ce66" disabled
             ></el-switch>
            <!-- <div>{{ typeof scope.row.CanViewTrace }}</div>   -->
          </template>
        </el-table-column>
        <el-table-column align="center" width="220" prop="CreateTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.CreateTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注">
          <template slot-scope="scope">
            {{ scope.row.Remark }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="300">
          <template slot-scope="scope" v-if="scope.row.RoleName != '管理员'">
            <el-button type="primary" size="small" @click="giveRole('add', scope.row)" icon="el-icon-user-solid">赋予角色</el-button>
            <el-button type="primary" size="small" @click="addPerson('edit', scope.row)" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="text-align: left; margin-top: 10px">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!--添加编辑人员-->
    <el-dialog class="deep_dialog" :before-close="handleClose"
      :visible.sync="showPersonInfo"
      width="540px" :close-on-click-modal="false"
    >
      <span slot="title">{{type == 'add' ? '添加人员' : '编辑人员'}}</span>
      <el-form
        :model="personInfo"
        label-width="116px"
        ref="personInfo"
        :rules="addrules"  autocomplete="off"
      >
        <el-form-item label="员工姓名" prop="name">
          <el-input autocomplete="off"
            style="width: 314px"
            v-model="personInfo.name"
            maxlength="15"
            clearable
            placeholder="请输入姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input autocomplete="off"
            style="width: 314px"
            v-model="personInfo.phone"
            clearable
            placeholder="请输入号码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="type !== 'edit'">
          <el-input placeholder="请输入密码"
           v-model="personInfo.password" style="width: 314px"
           class="no-autofill-pwd"
           type="text" />
        </el-form-item>
        <el-form-item label="密码" v-if="type === 'edit'" prop="newpassword">
          <el-input placeholder="请输入密码" clearable maxlength="12" minlength="6"
           v-model="personInfo.newpassword" style="width: 314px"
           class="no-autofill-pwd"
           type="text" />
        </el-form-item>
        <el-form-item label="员工职务" prop="job">
          <el-input autocomplete="off"
            style="width: 314px"
            v-model="personInfo.job"
            maxlength="20"
            clearable
            placeholder="请输入职位"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="使用状态" prop="state">
          <el-select
            style="width: 314px"
            v-model="personInfo.state"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in stateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看北斗轨迹" prop="canViewTrace">
          <el-switch v-model="personInfo.canViewTrace" active-color="#13ce66">
          </el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input autocomplete="off"
            style="width: 314px"
            v-model="personInfo.remark"
            maxlength="200"
            clearable
            placeholder="请输入备注"
            type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="type == 'add'"
          @click="addTable('personInfo')"
          >添加</el-button
        >
        <el-button
          type="primary"
          v-show="type == 'edit'"
          @click="onSumbitEdit('personInfo')"
          >确定修改</el-button
        >
        <el-button @click="closeDialog('personInfo')">取 消</el-button>
      </div>
    </el-dialog>
    <!--赋予角色弹框-->
    <el-dialog class="deep_dialog"
      width="800px"
      :visible.sync="giveRoleShow" :close-on-click-modal="false"
    >
      <span slot="title">{{roleType == 'add' ? '赋予角色' : '删除角色'}}</span>
      <el-table
        :data="roleData"
        :header-cell-style="{color: '#666', background: '#f0f0f0'}"
        @selection-change="selectRole"
        ref="myTable"
        height="40vh"
        v-loading="loading"
      >
        <el-table-column type="selection"  align="center" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          type="index"
          prop="UserID"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column align="center"  prop="Name" label="角色名称">
        </el-table-column>
        <!-- <el-table-column align="center" prop="company" label="所属公司">
       
      </el-table-column> -->
        <!-- <el-table-column align="center" prop="department" label="所属部门"  width="200">
         
      </el-table-column> -->
        <el-table-column align="center" prop="Remark" label="角色描述">
        </el-table-column>
        <el-table-column align="center" prop="PeopleNums" label="赋予人数">
        </el-table-column>
        <el-table-column align="center" prop="Status" label="使用状态">
           <template slot-scope="scope">
             <el-tag v-if="scope.row.Enabled !== 0" type="success">{{ scope.row.Status }}</el-tag>
             <el-tag v-else type="danger">{{ scope.row.Status }}</el-tag>
            <!-- <div :style="scope.row.Enabled === 0 ? 'color: #FF2323' : 'color: #13D298'">{{ scope.row.Status }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateDatetime"
          label="创建时间"
          width="200"
        >
        </el-table-column>
      </el-table>
      <!--    <el-pagination
        class="pagination"
        @current-change="handleCurrentChangeRole"
        :current-page.sync="paginationRole.page"
        :page-size="paginationRole.pagesize"
        layout="total, prev, pager, next"
        :total="paginationRole.total"
      >
      </el-pagination> -->
      <el-alert
        type="warning" style="margin-top:10px"
        :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br/>
          <span>1.勾选一个或多个角色，点击"确认"按钮，即可赋予角色</span><br/>
          <span>2.如需要减少角色，只需将序号前的√取消</span><br/>
        </span>
      </el-alert>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSureRole">{{
          roleType == "add" ? "确认" : "确认"
        }}</el-button>
        <el-button @click="giveRoleShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  addUser,
  editUser,
  DeleteUser,
  giveUserRole,
  getAllList,
} from "@/api/power";
import basicMixin from "@/mixins/basic";
import md5 from "js-md5";
export default {
  mixins: [basicMixin],
  data() {
    return {
      loading: false,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 获取角色列表分页
      paginationRole: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      stateOption: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      personInfo: {
        name: "",
        phone: "",
        job: "",
        state: "",
        remark: "",
        canViewTrace: false,
        password: '',
        newpassword:'',
      }, // 人员信息
      // 添加表单验证
      addrules: {
        name: [
          {
            required: true,
            message: "请输入员工姓名",
            max: 50,
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入员工手机号", trigger: "change" },
          {
            required: true,
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入6-12位的密码",min:6, max: 12, trigger: "change" }],

        job: [
          { required: true, message: "请输入职务", max: 50, trigger: "change" },
        ],
        state: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        remark: [{ message: "请填写备注信息", max: 500, trigger: "blur" }],
        newpassword: [{ message: "请输入6-12位的密码", min:6, max: 12,trigger: "blur" }]
      },
      showPersonInfo: false, // 显示添加编辑人员弹窗
      giveRoleShow: false, // 赋予角色弹框
      selectedTableItemList: [],
      personId: 0,
      type: "add", // add 为添加人员，edit为编辑
      editInfo: {
        ID: "",
      }, // 编辑的信息
      searchForm: {
        EnterpriseFullName: "", //企业名称
        dateTime: ["", ""], //时间范围
        name: "", //姓名
      },
      roleType: "", // 是赋予角色还是删除角色
      roleSelectionList: [], // 选择了的角色列表
      tableData: [], //表格数据
      roleData: [],
    };
  },
  methods: {
     // 让管理员账号不可删除
    selectable(row,index){
      if(row.RoleName == '管理员') return false
      return true

    },
    // 获取角色列表
    getRoleList() {
      let params = {
        RoleName: "",
        DateStart: "",
        DateEnd: "",
        pageSize: this.paginationRole.pagesize,
        pageIndex: this.paginationRole.page,
        userId: 0,
      };
      this.loading = true
      getRoleList(JSON.stringify(params)).then((res) => {
        this.roleData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.loading = false
        this.paginationRole.total = Number(res.data.TotalCount);
      }).finally(() => { this.loading = false });
    },
    // 关闭弹窗
    handleClose(done) {
      done();
      this.$refs['personInfo'].resetFields();
    },
    // 获取人员列表
    getUserLists() {
      let params = {
        UserName: this.searchForm.name,
        DateStart: this.searchForm.dateTime[0],
        DateEnd: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      };
      this.loading = true
      getUserList(params).then((res) => {
        this.tableData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      }).finally(() => {this.loading = false});
    },
    // 添加人员
    addTable(personInfo) {
      this.$refs[personInfo].validate((valid) => {
        if (valid) {
          let params = {
            ChineseName: this.personInfo.name,
            Name: this.personInfo.phone,
            Enabled: this.personInfo.state,
            IdentityCard: '',
            Remark: this.personInfo.remark,
            title: this.personInfo.job,
            CanViewTrace: Number(this.personInfo.canViewTrace),
            password: md5(String(this.personInfo.password))
          };
          addUser(params).then((res) => {
            this.showPersonInfo = false;
            this.$refs[personInfo].resetFields();
            this.pagination.page = 1;
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getUserLists();
          });
        }
      });
    },
    // 修改人员
    onSumbitEdit(personInfo) {
      
      this.$refs[personInfo].validate((valid) => {
        if (valid) {
          let params = {
            ID: this.editInfo.ID,
            ChineseName: this.personInfo.name,
            Name: this.personInfo.phone,
            Enabled: this.personInfo.state,
            // this.personInfo.num.toUpperCase()
            IdentityCard: '',
            Remark: this.personInfo.remark,
            title: this.personInfo.job,
            CanViewTrace: Number(this.personInfo.canViewTrace),
            password: md5(String(this.personInfo.newpassword)) || this.personInfo.password
          };
          editUser(params).then((res) => {
            this.showPersonInfo = false;
            this.$refs[personInfo].resetFields();
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getUserLists();
          });
        }
      });
    },
    closeDialog(personInfo){
      this.showPersonInfo = false;
      this.$refs[personInfo].resetFields();
    },
    // 赋予角色
    onSureRole() {
      let arr = [];
      this.roleSelectionList.forEach((item) => {
        arr.push(item.ID);
      });
      let param = arr.join(",");
      if (this.roleType == "add") {
        let params = {
          userId: this.personId,
          roleIds: param,
        };
        giveUserRole(params).then((res) => {
          this.paginationRole.page = 1;
          this.pagination.page = 1;
          this.$message({
            message: "赋予角色成功",
            type: "success",
          });
          this.giveRoleShow = false;
          this.getUserLists();
        });
      } else if (this.roleType == "delete") {
        let params = {
          userId: this.personId,
          roleIds: param,
          status: 0,
        };
        giveUserRole(params).then((res) => {
          this.paginationRole.page = 1;
          this.pagination.page = 1;
          this.$message({
            message: "删除角色成功",
            type: "success",
          });
          this.giveRoleShow = false;
          this.getUserLists();
        });
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.getUserLists();
    },
    //搜索
    search(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.searchForm.dateTime === null ){
            this.searchForm.dateTime = ["", ""]
          }
          this.pagination.page = 1;
          this.getUserLists();
        }
      });
    },
    //清空
    resetForm() {
      this.searchForm.EnterpriseFullName = "";
      this.searchForm.name = "";
      this.searchForm.dateTime = ["",""];
      this.pagination.page = 1;
      this.getUserLists();
    },
    // 选择人员角色
    selectRole(val) {
      this.roleSelectionList = val;
    },
    //新增
    addPerson(scope, row) {
      this.showPersonInfo = true;
      if (scope == "add") {
        this.title = "add";
        this.type = "add";
        // this.$refs["personInfo"].resetFields();
        this.personInfo.name = "";
        this.personInfo.phone = "";
        this.personInfo.num = "";
        this.personInfo.job = "";
        this.personInfo.state = "";
        this.personInfo.remark = "";
        this.personInfo.canViewTrace = false;
        this.personInfo.password = '';
      } else if (scope == "edit") {
        this.type = "edit";
        this.editInfo = row;
        this.personInfo.name = row.ChineseName;
        this.personInfo.phone = row.Name;
        this.personInfo.job = row.Title;
        this.personInfo.state = row.Enabled;
        this.personInfo.remark = row.Remark;
        this.personInfo.canViewTrace = !!row.CanViewTrace;
        this.personInfo.password = row.PassWord
      }
    },
    // 赋予角色弹框
    giveRole(type, row) {
      this.roleType = type;
      this.personId = row.ID;
      this.giveRoleShow = true;
      if (type == "add") {
        this.title = "add";
      } else if (type == "delete") {
        this.roleType = "delete";
      }
      let params = {
        userId: this.personId,
      };
      getAllList(params).then((res) => {
        this.roleSelectionList = [];
        this.roleData = res.data;
        this.$nextTick(() => {
          res.data.forEach((row) => {
            if (row.Own) {
              this.roleSelectionList.push(row);
              this.$refs.myTable.toggleRowSelection(row, true);
            }
          });
        });
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.myTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.myTable.clearSelection();
      }
    },
    //批量删除
    deleteUser() {
      if (this.selectedTableItemList.length === 0) {
        this.$message.error("请在表格中勾选要删除的数据");
        return;
      }
      let params = [];
      this.selectedTableItemList.forEach((item) => {
        params.push(item.ID);
      });
      let param = params.join(",");
      this.$confirm(
        "您将删除此员工,关于此员工的所有数据将清空，是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          DeleteUser(param).then((res) => {
            this.pagination.page = 1;
            this.searchForm.EnterpriseFullName = "";
            this.searchForm.name = "";
            this.searchForm.dateTime = ["",""];
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getUserLists();
          });
        })
        .catch(() => {});
    },

    
  },

  created() {
    this.getUserLists();
  },
};
</script>
<style lang="scss">
.personAdmin {
  .addPerson {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  
  }
  }
.infos {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 16px;
  width: 320px;
  display: flex;
  justify-content: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__label {
    text-align: left;
    color: #333333;
  }
  .el-form-item__error {
    left: 14px !important;
  }
  .el-input__inner {
    border: none;
  }
}
.info {
  margin-bottom: 16px;
  width: 320px;
  font-size: 14px;
  padding-left: 6px;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-textarea__inner {
    height: 80px;
  }
  .name {
    margin-bottom: 8px;
    font-size: 14px;
    color: #333333;
  }
  .el-input__inner {
    border: none;
  }
}
}
</style>
<style lang="scss" scoped>
.personAdmin {
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .roleBtn {
    background:#3c3aa5;
    display: flex;
    align-items: center;
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
    img {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .btns {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
  ::v-deep .no-autofill-pwd {
    .el-input__inner {
      -webkit-text-security: disc !important;
    }
  }
}
</style>