import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 添加角色
export const addRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/AddRole`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 更新角色
export const editRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/EditRole`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除角色
export const DeleteRole = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/DeleteRole?request=${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取角色列表
export const getRoleList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/UserRole/GetRoles?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 查询用户/角色菜单权限
export const getUserMenus = (params) => {
    return new Promise((resolve, reject) => {  
        axios.get(`${host}/api/UserRole/GetUserMenus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 赋予角色菜单 未知
export const giveRoleMenus = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/UserRole/GiveRoleMenus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 根据powerID 获取munuid 用来回显  未知
export const getSelectMenuId = (params) => {
    return new Promise((resolve, reject) => {  
        axios.get(`${host}/api/UserRole/PorwerIdToMenuId?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 添加用户
export const addUser = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/AddUser`, params, {
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 更新用户
export const editUser = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/EditUser`, params, {
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 删除用户
export const DeleteUser = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/DeleteUser?request=${params}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
//获取用户列表
export const getUserList = (params) => {
  return new Promise((resolve, reject) => {  
      axios.get(`${host}/api/UserRole/GetUsers?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 获取用户全部角色
export const getAllList = (params) => {
  return new Promise((resolve, reject) => {  
      axios.get(`${host}/api/UserRole/GetRolesAll?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 赋予用户角色
export const giveUserRole = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/GiveUserRole?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 查询菜单树/无分页
export const getMenusAll = () => {
  return new Promise((resolve, reject) => { 
      axios.get(`${host}/api/UserRole/GetMenusAll`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 更新身份权限
export const updateAdminPowers = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/UpdateAdminPowers?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 添加系统身份
export const addIdentity = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/AddIdentity`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
//更新系统身份
export const editIdentity = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/EditIdentity`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
//赋予身份权限
export const giveIdentityPowers = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/UserRole/GiveIdentityPowers`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 查询单个系统身份信息
export const getUserIdentity = (params) => {
  return new Promise((resolve, reject) => {  
      axios.get(`${host}/api/UserRole/GetUserIdentity?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}